/* eslint-disable no-unused-vars */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { NextPageContext } from 'next';
import NextErrorComponent, { ErrorProps } from 'next/error';

import { useTheme } from '@mui/material/styles';

import Link from 'components/Link';
import Seo from 'components/Seo/Seo';

import { convertErrorToEnumerable, serverLogger } from 'utils/serverLogs.utils';

const ErrorFound = () => {
  const theme = useTheme();

  return (
    <>
      <Seo title="404 | Creoate" />
      <Container maxWidth="md" sx={{ my: theme.spacing(10) }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography align="center" color="textPrimary" variant="h1">
            Whoops!
          </Typography>
          <Typography align="center" color="textPrimary" variant="h5" sx={{ mt: theme.spacing(1.5) }}>
            This server is not available
          </Typography>

          <Typography align="center" color="textPrimary" variant="h6" sx={{ mt: theme.spacing(1.5) }}>
            Try searching or
          </Typography>

          <Link href="/" sx={{ cursor: 'pointer' }}>
            <Button sx={{ mt: theme.spacing(1.5) }} variant="contained">
              GO TO HOMEPAGE
            </Button>
          </Link>
        </Box>
      </Container>
    </>
  );
};

/**
 *
 * @param {NextPageContext} context
 * @returns {Promise<ErrorProps>}
 */
ErrorFound.getInitialProps = async (context) => {
  const { req, res, err, asPath } = context;
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err,
  });

  if (errorInitialProps.statusCode && errorInitialProps.statusCode < 500) {
    return errorInitialProps;
  }

  const { retailerId } = JSON.parse(req?.cookies?.user || '{}');
  const logContext = {
    retailerId,
    error: err ? convertErrorToEnumerable(err) : null,
    cookie: req?.cookies,
    headers: req?.headers,
    lastNavigatedPage: asPath ?? null,
    userDateTime: new Date().toISOString(),
    errorInitialProps,
  };
  serverLogger.error(`Whoops page triggered by default Next.js error handler`, logContext, 'generic error', 'server');

  return errorInitialProps;
};

export default ErrorFound;
